import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {LOG_IN_PREFIX, setUserSession, setMfaRemind} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";
import { FETCH_SELF_REGISTRATION_PREFIX } from "../../reducers/setting";
import { MFAComponent } from '../../components/Auth/MFAComponent';

const mapStateToProps = state => {
  return {
    tempLoginData: state.auth.tempLoginData,
    user: state.auth.data,
    token: state.auth.token,
    isSelfRegistrationEnabled: state.setting.isSelfRegistrationEnabled,
    loading: createLoadingSelector([LOG_IN_PREFIX, FETCH_SELF_REGISTRATION_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setUserSession,
  setMfaRemind
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MFAComponent);
