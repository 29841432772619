import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { appTheme, routes } from '../../config';
import ThreeDotsIcon from './ThreeDotsIcon';

class UserListActions extends PureComponent {
  state = {
    isOpen: false
  };

  toggleDropdown = () => {
    if (this.props.disabled) return;
    this.setState({ isOpen: !this.state.isOpen })
  };

  render() {
    const {
      resetUserMFA,
      toggleResetPasswordModal,
      toggleDeleteUserModal,
      t, user, selectedTheme
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="">
        <Dropdown
          className='multiSelectDropdown v2'
          isOpen={isOpen}
          toggle={this.toggleDropdown}
        >
          <DropdownToggle>
            <ThreeDotsIcon />
          </DropdownToggle>
          <DropdownMenu>
          {!user.superadmin && <> <DropdownItem
              className={`item-`}
              value={''}
              onClick={() => {
                toggleDeleteUserModal()
                this.toggleDropdown();
              }}
            >
              Delete user <i title={t('auth.del_user')} className="mr-3"><img
                src={"/assets/images/icn-delete-user.svg"} alt='' /></i>
            </DropdownItem>

            <DropdownItem
              className={`item-`}
              value={''}
              onClick={() => {
                toggleResetPasswordModal()
                this.toggleDropdown();
              }}
            >
              Reset password <i title={t('auth.reset_pass')} className="mr-3" onClick={() => this.toggleResetPasswordModal(user)}><img
                src={"/assets/images/icn-reset password.svg"} alt='' /></i>
            </DropdownItem>

            <DropdownItem
              className={`item-`}
              value={''}
              onClick={() => {
                this.props.history.push(`${routes.users.path}/${user.id}`)
                this.toggleDropdown();
              }}
            >
              Edit user <i title={t("Edit_User")} color='info'>
                <img src={`/assets/images/edit${selectedTheme === appTheme.DARK ? "-dark" : ""}.svg`} alt='' />
              </i>
            </DropdownItem>
            </>}
            
            {user.mfa?.UserSub && <DropdownItem
              className={`item-`}
              value={''}
              onClick={() => {
                resetUserMFA()
                this.toggleDropdown();
              }}
            >
              Reset MFA
            </DropdownItem>}


          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export default withTranslation('common')(withRouter(UserListActions));
