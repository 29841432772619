import jwt_decode from 'jwt-decode';
import _ from "lodash";
import {
  StorageService,
  BaseApiService,
  ToastrService,
  AuthApiService,
  UserApiService,
  getTwilioClient,
  SoundService,
  SocketService,
} from '../services';
import { appSounds, callStatusesForParticipant, constantMessages, errorCodes, routes, userStatuses } from '../config';
import history from "../helpers/history";
import { getTwilioVideoToken, getTwilioToken } from "./twilio";
import {
  addSocketEvents,
  CHANGE_CHAT_STATUS_ACTION
} from "./user";
import { checkMediaPermissions, endCall } from "./calls";
import NotificationService from "../services/notificationService";
import { getAcceptedCall } from "../helpers/calls";
import store from "../store";
import i18n from '../i18n';
import { Auth as CognitoAuth } from 'aws-amplify';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const LOG_IN_PREFIX = 'auth/LOG_IN';
export const LOG_IN_REQUEST_ACTION = LOG_IN_PREFIX + '_REQUEST_ACTION';
export const LOG_IN_SUCCESS_ACTION = LOG_IN_PREFIX + '_SUCCESS_ACTION';
export const LOG_IN_FAILURE_ACTION = LOG_IN_PREFIX + '_FAILURE_ACTION';

export const REGISTER_PREFIX = 'auth/REGISTER';
export const REGISTER_REQUEST_ACTION = REGISTER_PREFIX + '_REQUEST_ACTION';
export const REGISTER_SUCCESS_ACTION = REGISTER_PREFIX + '_SUCCESS_ACTION';
export const REGISTER_FAILURE_ACTION = REGISTER_PREFIX + '_FAILURE_ACTION';

export const VERIFY_EMAIL_PREFIX = 'auth/VERIFY_EMAIL';
export const VERIFY_EMAIL_REQUEST_ACTION = VERIFY_EMAIL_PREFIX + '_REQUEST_ACTION';
export const VERIFY_EMAIL_SUCCESS_ACTION = VERIFY_EMAIL_PREFIX + '_SUCCESS_ACTION';
export const VERIFY_EMAIL_FAILURE_ACTION = VERIFY_EMAIL_PREFIX + '_FAILURE_ACTION';

export const VERIFY_MOBILE_PREFIX = 'auth/VERIFY_MOBILE';
export const VERIFY_MOBILE_REQUEST_ACTION = VERIFY_MOBILE_PREFIX + '_REQUEST_ACTION';
export const VERIFY_MOBILE_SUCCESS_ACTION = VERIFY_MOBILE_PREFIX + '_SUCCESS_ACTION';
export const VERIFY_MOBILE_FAILURE_ACTION = VERIFY_MOBILE_PREFIX + '_FAILURE_ACTION';

export const RESEND_EMAIL_VERIFY_PREFIX = 'auth/RESEND_EMAIL_VERIFY';
export const RESEND_EMAIL_VERIFY_REQUEST_ACTION = RESEND_EMAIL_VERIFY_PREFIX + '_REQUEST_ACTION';
export const RESEND_EMAIL_VERIFY_SUCCESS_ACTION = RESEND_EMAIL_VERIFY_PREFIX + '_SUCCESS_ACTION';
export const RESEND_EMAIL_VERIFY_FAILURE_ACTION = RESEND_EMAIL_VERIFY_PREFIX + '_FAILURE_ACTION';

export const RESEND_PHONE_VERIFY_PREFIX = 'auth/RESEND_PHONE_VERIFY';
export const RESEND_PHONE_VERIFY_REQUEST_ACTION = RESEND_PHONE_VERIFY_PREFIX + '_REQUEST_ACTION';
export const RESEND_PHONE_VERIFY_SUCCESS_ACTION = RESEND_PHONE_VERIFY_PREFIX + '_SUCCESS_ACTION';
export const RESEND_PHONE_VERIFY_FAILURE_ACTION = RESEND_PHONE_VERIFY_PREFIX + '_FAILURE_ACTION';

export const SEND_FORGOT_PASSWORD_PREFIX = 'auth/SEND_FORGOT_PASSWORD';
export const SEND_FORGOT_PASSWORD_REQUEST_ACTION = SEND_FORGOT_PASSWORD_PREFIX + '_REQUEST_ACTION';
export const SEND_FORGOT_PASSWORD_SUCCESS_ACTION = SEND_FORGOT_PASSWORD_PREFIX + '_SUCCESS_ACTION';
export const SEND_FORGOT_PASSWORD_FAILURE_ACTION = SEND_FORGOT_PASSWORD_PREFIX + '_FAILURE_ACTION';

export const RESET_PASSWORD_PREFIX = 'auth/RESET_FORGOT_PASSWORD';
export const RESET_PASSWORD_REQUEST_ACTION = RESET_PASSWORD_PREFIX + '_REQUEST_ACTION';
export const RESET_PASSWORD_SUCCESS_ACTION = RESET_PASSWORD_PREFIX + '_SUCCESS_ACTION';
export const RESET_PASSWORD_FAILURE_ACTION = RESET_PASSWORD_PREFIX + '_FAILURE_ACTION';

export const SET_PASSWORD_PREFIX = 'auth/SET_FORGOT_PASSWORD';
export const SET_PASSWORD_REQUEST_ACTION = SET_PASSWORD_PREFIX + '_REQUEST_ACTION';
export const SET_PASSWORD_SUCCESS_ACTION = SET_PASSWORD_PREFIX + '_SUCCESS_ACTION';
export const SET_PASSWORD_FAILURE_ACTION = SET_PASSWORD_PREFIX + '_FAILURE_ACTION';

export const CHANGE_PASSWORD_PREFIX = 'auth/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_REQUEST_ACTION = CHANGE_PASSWORD_PREFIX + '_REQUEST_ACTION';
export const CHANGE_PASSWORD_SUCCESS_ACTION = CHANGE_PASSWORD_PREFIX + '_SUCCESS_ACTION';
export const CHANGE_PASSWORD_CLOSE_MODAL = CHANGE_PASSWORD_PREFIX + '_CLOSE_MODAL';

export const CHANGE_EMAIL_PREFIX = 'auth/CHANGE_EMAIL';
export const CHANGE_EMAIL_REQUEST_ACTION = CHANGE_EMAIL_PREFIX + '_REQUEST_ACTION';
export const CHANGE_EMAIL_SUCCESS_ACTION = CHANGE_EMAIL_PREFIX + '_SUCCESS_ACTION';
export const CHANGE_EMAIL_FAILURE_ACTION = CHANGE_EMAIL_PREFIX + '_FAILURE_ACTION';

export const CHANGE_PHONE_NUMBER_PREFIX = 'auth/CHANGE_PHONE_NUMBER';
export const CHANGE_PHONE_NUMBER_REQUEST_ACTION = CHANGE_PHONE_NUMBER_PREFIX + '_REQUEST_ACTION';
export const CHANGE_PHONE_NUMBER_SUCCESS_ACTION = CHANGE_PHONE_NUMBER_PREFIX + '_SUCCESS_ACTION';
export const CHANGE_PHONE_NUMBER_FAILURE_ACTION = CHANGE_PHONE_NUMBER_PREFIX + '_FAILURE_ACTION';

export const CHANGE_PERSONAL_INFORMATION_PREFIX = 'auth/CHANGE_PERSONAL_INFORMATION';
export const CHANGE_USER_PERMISSIONS = 'auth/CHANGE_USER_PERMISSIONS';
export const CHANGE_PERSONAL_INFORMATION_REQUEST_ACTION = CHANGE_PERSONAL_INFORMATION_PREFIX + '_REQUEST_ACTION';
export const CHANGE_PERSONAL_INFORMATION_SUCCESS_ACTION = CHANGE_PERSONAL_INFORMATION_PREFIX + '_SUCCESS_ACTION';
export const CHANGE_PERSONAL_INFORMATION_FAILURE_ACTION = CHANGE_PERSONAL_INFORMATION_PREFIX + '_FAILURE_ACTION';

export const CHANGE_AVATAR_PREFIX = 'auth/CHANGE_AVATAR';
export const CHANGE_AVATAR_REQUEST_ACTION = CHANGE_AVATAR_PREFIX + '_REQUEST_ACTION';
export const CHANGE_AVATAR_SUCCESS_ACTION = CHANGE_AVATAR_PREFIX + '_SUCCESS_ACTION';
export const CHANGE_AVATAR_FAILURE_ACTION = CHANGE_AVATAR_PREFIX + '_FAILURE_ACTION';

export const REMOVE_AVATAR_PREFIX = 'auth/REMOVE_AVATAR';
export const REMOVE_AVATAR_REQUEST_ACTION = REMOVE_AVATAR_PREFIX + '_REQUEST_ACTION';
export const REMOVE_AVATAR_SUCCESS_ACTION = REMOVE_AVATAR_PREFIX + '_SUCCESS_ACTION';
export const REMOVE_AVATAR_FAILURE_ACTION = REMOVE_AVATAR_PREFIX + '_FAILURE_ACTION';

export const FETCH_CURRENT_USER_PREFIX = 'auth/FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_REQUEST_ACTION = FETCH_CURRENT_USER_PREFIX + '_REQUEST_ACTION';
export const FETCH_CURRENT_USER_SUCCESS_ACTION = FETCH_CURRENT_USER_PREFIX + '_SUCCESS_ACTION';
export const FETCH_CURRENT_USER_FAILURE_ACTION = FETCH_CURRENT_USER_PREFIX + '_FAILURE_ACTION';

export const TRIAL_LOG_IN_PREFIX = 'auth/TRIAL_LOG_IN';
export const TRIAL_LOG_IN_REQUEST_ACTION = TRIAL_LOG_IN_PREFIX + '_REQUEST_ACTION';
export const TRIAL_LOG_IN_SUCCESS_ACTION = TRIAL_LOG_IN_PREFIX + '_SUCCESS_ACTION';
export const TRIAL_LOG_IN_FAILURE_ACTION = TRIAL_LOG_IN_PREFIX + '_FAILURE_ACTION';

export const LOG_OUT_PREFIX = 'auth/LOG_OUT';
export const LOG_OUT_REQUEST_ACTION = LOG_OUT_PREFIX + '_REQUEST_ACTION';
export const LOG_OUT_SUCCESS_ACTION = LOG_OUT_PREFIX + '_SUCCESS_ACTION';
export const LOG_OUT_FAILURE_ACTION = LOG_OUT_PREFIX + '_FAILURE_ACTION';

export const SET_SETTINGS_PREFIX = 'auth/SET_SESSION_TIMEOUT';
export const SET_SETTINGS_REQUEST_ACTION = SET_SETTINGS_PREFIX + '_REQUEST_ACTION';
export const SET_SETTINGS_SUCCESS_ACTION = SET_SETTINGS_PREFIX + '_SUCCESS_ACTION';
export const SET_SETTINGS_FAILURE_ACTION = SET_SETTINGS_PREFIX + '_FAILURE_ACTION';

export const UPDATE_SETTINGS_PREFIX = 'auth/UPDATE_SESSION_TIMEOUT';
export const UPDATE_SETTINGS_REQUEST_ACTION = UPDATE_SETTINGS_PREFIX + '_REQUEST_ACTION';
export const UPDATE_SETTINGS_SUCCESS_ACTION = UPDATE_SETTINGS_PREFIX + '_SUCCESS_ACTION';
export const UPDATE_SETTINGS_FAILURE_ACTION = UPDATE_SETTINGS_PREFIX + '_FAILURE_ACTION';

export const GET_SETTINGS_PREFIX = 'auth/GET_SETTINGS';
export const GET_SETTINGS_REQUEST_ACTION = GET_SETTINGS_PREFIX + '_REQUEST_ACTION';
export const GET_SETTINGS_SUCCESS_ACTION = GET_SETTINGS_PREFIX + '_SUCCESS_ACTION';
export const GET_SETTINGS_FAILURE_ACTION = GET_SETTINGS_PREFIX + '_FAILURE_ACTION';

export const REFRESH_TOKEN_PREFIX = 'auth/UPDATE_TOKEN';
export const REFRESH_TOKEN_REQUEST_ACTION = REFRESH_TOKEN_PREFIX + '_REQUEST_ACTION';
export const REFRESH_TOKEN_SUCCESS_ACTION = REFRESH_TOKEN_PREFIX + '_SUCCESS_ACTION';
export const REFRESH_TOKEN_FAILURE_ACTION = REFRESH_TOKEN_PREFIX + '_FAILURE_ACTION';
export const TOGGLE_PASSWORD_UPDATE_MODAL = 'auth/PASSWORD_UPDATE_MODAL'
export const UPDATE_APP_CONNECTION_STATUS = 'auth/UPDATE_APP_CONNECTION_STATUS ';
export const SAVE_AUTH_BEFORE_MFA = 'SAVE_AUTH_BEFORE_MFA';

export const SET_IDLE_TIMER = 'auth/SET_IDLE_TIMER';

export const CHANGE_CURRENT_USER_ROLE_SUCCESS = 'auth/CHANGE_CURRENT_USER_ROLE_SUCCESS';

export const RESET = 'auth/RESET';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  data: {
    settings: {}
  },
  role: "",
  token: '',
  settings: {},
  isAppDisabled: false,
  idleTimer: null,
  isPaswordUpdateModalOpen: false,
  isPaswordUpdated: false
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN_FAILURE_ACTION:
    case REGISTER_SUCCESS_ACTION:
      return {
        ...state,
        data: {
          ...state.data,
          email: action.payload.email,
          phone: action.payload.phone,
          phoneCountryCode: action.payload.phoneCountryCode,
          phoneCountryName: action.payload.phoneCountryName,
          tempToken: action.payload.tempToken
        }
      };
    case CHANGE_PHONE_NUMBER_SUCCESS_ACTION:
    case CHANGE_EMAIL_SUCCESS_ACTION:
      return {
        ...state,
        data: {
          ...state.data,
          ...(action.payload || {})
        }
      };
    case SAVE_AUTH_BEFORE_MFA:
      return {
        ...state,
        tempLoginData: action.payload
      }
    case LOG_IN_SUCCESS_ACTION:
    case TRIAL_LOG_IN_SUCCESS_ACTION:
    case FETCH_CURRENT_USER_SUCCESS_ACTION:
      return {
        ...state,
        data: { ...action.payload.data },
        token: action.payload.token,
        role: action.payload.role,
      };
    case VERIFY_EMAIL_SUCCESS_ACTION:
      let data = state.data || {};
      if (state.data?.unverifiedEmail) {
        data = {
          ...state.data,
          email: state.data.unverifiedEmail,
          unverifiedEmail: ""
        }
      } else if (action.payload && action.payload.phoneVerified !== undefined) {
        data = {
          ...state.data,
          phoneVerified: action.payload.phoneVerified,
        }
      }

      return {
        ...state,
        data,
      };
    case VERIFY_MOBILE_SUCCESS_ACTION:
      const newData = state.data && state.data.unverifiedPhone ? {
        ...state.data,
        phone: state.data.unverifiedPhone,
        unverifiedPhone: ""
      } : state.data;
      return {
        ...state,
        data: newData,
      };
    case CHANGE_PERSONAL_INFORMATION_SUCCESS_ACTION:
      return {
        ...state,
        data: { ...action.payload.updatedUser },
      };
    case CHANGE_CURRENT_USER_ROLE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          role: action.payload.role
        }
      };
    case CHANGE_AVATAR_SUCCESS_ACTION:
      return {
        ...state,
        data: {
          ...state.data,
          avatar: action.payload.data.path
        }
      };
    case REMOVE_AVATAR_SUCCESS_ACTION:
      const { avatar, ...withoutAvatar } = state.data;
      return {
        ...state,
        data: withoutAvatar
      };
    case LOG_OUT_SUCCESS_ACTION: {
      return {
        data: {},
        token: '',
        isAppDisabled: action.payload
      }
    }
    case UPDATE_SETTINGS_SUCCESS_ACTION: {
      return {
        ...state,
        data: action.payload.user,
      }
    }
    case CHANGE_USER_PERMISSIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          permissions: { ...action.payload }
        }
      }
    }
    case UPDATE_APP_CONNECTION_STATUS:
      return {
        ...state,
        isAppDisabled: action.payload
      }
    case GET_SETTINGS_SUCCESS_ACTION:
      return {
        ...state,
        settings: action.payload.settings
      }
    case SET_SETTINGS_SUCCESS_ACTION:
      const settings = _.cloneDeep(state.data.settings);

      if (action.payload.setting?.key) {
        settings[action.payload.setting.key] = action.payload.setting.value;
      }
      return {
        ...state,
        data: {
          ...state.data,
          settings
        }
      }
    case SET_IDLE_TIMER:
      return {
        ...state,
        idleTimer: action.payload
      }
    case REFRESH_TOKEN_SUCCESS_ACTION:
      return {
        ...state,
        token: action.payload
      }
    case TOGGLE_PASSWORD_UPDATE_MODAL:
      return {
        ...state,
        isPaswordUpdateModalOpen: action.payload
      }
    case CHANGE_PASSWORD_SUCCESS_ACTION:
      return {
        ...state,
        isPaswordUpdated: true
      }
    default:
      return state
  }
}

// ACTIONS /////////////////////////////////////////////////////////////////////

export function toggleUpdatePasswordModal(isOpen) {
  return (dispatch, getState) => {
    dispatch({
      type: TOGGLE_PASSWORD_UPDATE_MODAL,
      payload: typeof isOpen === 'boolean' ? isOpen : !getState().auth.isPaswordUpdateModalOpen
    })
  }
}

export function trialLogInAction() {
  return (dispatch) => {
    dispatch({ type: TRIAL_LOG_IN_REQUEST_ACTION });
    const storageService = new StorageService();
    const token = storageService.checkUserToken();
    if (!!token) {
      BaseApiService.token = token;
      dispatch(fetchCurrentUserAction(token, true));
      dispatch({ type: TRIAL_LOG_IN_SUCCESS_ACTION, payload: { token, data: {}, role: "" } });
    } else {
      BaseApiService.token = '';
      dispatch({ type: TRIAL_LOG_IN_FAILURE_ACTION });
    }
  }
}

async function logInSucceed(dispatch, token, data, role, newLogIn) {
  BaseApiService.token = token;
  const storageService = new StorageService();
  const userSessions = storageService.checkUserSessions() || {}
  if (userSessions[data.id]?.length) {
    storageService.updateUserSessions({
      ...userSessions,
      [data.id]: [...userSessions[data.id], "active"]
    })
  } else {
    storageService.updateUserSessions({ [data.id]: ["active"] })
  }
  const userData = data || jwt_decode(token);
  NotificationService.requestPermission()
  checkMediaPermissions();
  dispatch({
    type: newLogIn ? LOG_IN_SUCCESS_ACTION : FETCH_CURRENT_USER_SUCCESS_ACTION,
    payload: { data: userData, token, role }
  });
  await SocketService.connect(token);
  addSocketEvents();
}


async function saveUserCredentials(dispatch, token, user, tempToken, role) {
  const storageService = new StorageService();

  storageService.saveUserToken(token);
  await logInSucceed(dispatch, token, { ...user, tempToken }, role, true);
  dispatch(getTwilioToken(true, true));
  dispatch(getTwilioVideoToken(true));
}

export function setUserSession({ token, user, tempToken, role, cognitoSession }) {
  return dispatch => {
    return saveUserCredentials(dispatch, token, {...user, cognitoSession }, tempToken, role);
  }
}

export function setMfaRemind({ token, user, tempToken, role, cognitoSession }, remind = false) {
  return dispatch => {
    const userService = new UserApiService();
    return userService.resetMfaData({ remind, userId: user.id }).finally(() => {
      saveUserCredentials(dispatch, token, {...user, cognitoSession }, tempToken, role);
    })
  }
}

export function logInAction(input) {
  return dispatch => {
    dispatch({ type: LOG_IN_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService.logIn(input)
      .then(async ({ data = {} }) => {
        const { tempToken, token, user, role } = data;
        if (user.mfa?.remind === false && user.permissions?.mfa === false) {
          await saveUserCredentials(dispatch, token, user, tempToken, role)
        } else {
          CognitoAuth.signIn(input).then(async (res) => {
            // save auth data somewhere
            dispatch({
              type: SAVE_AUTH_BEFORE_MFA,
              payload: { token, user, tempToken, role, cognitoUser: res, cred: input }
            });
            dispatch({ type: LOG_IN_SUCCESS_ACTION, payload: {}})
            // goto mfa page
            history.push(routes.mfaPage.path)
            // await saveUserCredentials(dispatch, token, user, tempToken, role)
          }).catch(async (error) => {
            // Continue login without mfa
            await saveUserCredentials(dispatch, token, user, tempToken, role)
          })
        }
        

      }).catch(err => {
        console.log("ERR DATA", err, err.data?.tempToken)
        const message = err.message || i18n.t('misc.err_incorrect_username');
        dispatch({
          type: LOG_IN_FAILURE_ACTION,
          payload: {
            email: err.data?.email,
            phone: err.data?.phone,
            phoneCountryCode: err.data?.phoneCountryCode,
            phoneCountryName: err.data?.phoneCountryName,
            tempToken: err.data?.tempToken,
          }
        });
        if (err.data?.errorCode === errorCodes.EMAIL_NOT_VERIFIED) {
          history.push(routes.emailConfirm.path)
        } else if (err.data?.errorCode === errorCodes.PHONE_NOT_VERIFIED) {
          history.push(routes.mobileConfirm.path)
        } else {
          ToastrService.error(message)
        }
      })

  }
}

export function registerAction(data) {
  return dispatch => {
    dispatch({ type: REGISTER_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService.register(data)
      .then((response) => {
        dispatch({
          type: REGISTER_SUCCESS_ACTION,
          payload: { message: constantMessages.registerUserSuccess, ...response.data.user }
        });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: REGISTER_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })

  }
}

export function verifyEmailAction(data) {
  return (dispatch, getState) => {
    dispatch({ type: VERIFY_EMAIL_REQUEST_ACTION });
    const { data: { tempToken }, token: stateToken } = getState().auth;
    const authService = new AuthApiService();
    return authService.verifyEmail({ ...data, tempToken: tempToken || stateToken })
      .then(async response => {
        const { phoneVerified, token, user, role, attemptLeft } = response?.data || {}

        if (attemptLeft) {
          ToastrService.info(i18n.t('attemptLeft', { attemptLeft: attemptLeft }))
        }

        if (!stateToken && !!token && !!user && !!role) {
          const storageService = new StorageService();
          storageService.saveUserToken(token);
          await logInSucceed(dispatch, token, user, role, true);
          dispatch(fetchCurrentUserAction(token, true));
        } else {
          dispatch({ type: VERIFY_EMAIL_SUCCESS_ACTION, payload: { phoneVerified } });
        }
        ToastrService.success(i18n.t('misc.success_email_verified'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        if (message === i18n.t('misc.err_phone_format')) {
          dispatch({ type: VERIFY_EMAIL_SUCCESS_ACTION });
          ToastrService.success(i18n.t('misc.success_email_verified'));
          ToastrService.error(message)
        } else {
          dispatch({ type: VERIFY_EMAIL_FAILURE_ACTION, payload: { message } });
          ToastrService.error(message)
        }
      })
  }
}

export function resendEmailVerifyAction(email) {
  return dispatch => {
    dispatch({ type: RESEND_EMAIL_VERIFY_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService.resendEmailVerify(email)
      .then(() => {
        dispatch({ type: RESEND_EMAIL_VERIFY_SUCCESS_ACTION });
        ToastrService.success(i18n.t('misc.succes_email_sent'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: RESEND_EMAIL_VERIFY_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function resendPhoneVerifyAction(data) {
  return dispatch => {
    dispatch({ type: RESEND_PHONE_VERIFY_REQUEST_ACTION });
    const authService = new AuthApiService();
    return authService.resendMobileVerify(data)
      .then(({ data: { attemptLeft } }) => {
        if (attemptLeft) {
          ToastrService.info(i18n.t('attemptLeft', { attemptLeft }))
        }

        dispatch({ type: RESEND_PHONE_VERIFY_SUCCESS_ACTION });
        ToastrService.success(i18n.t('misc.success_sms_sent'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: RESEND_PHONE_VERIFY_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function verifyMobileAction(data) {
  return (dispatch, getState) => {
    dispatch({ type: VERIFY_MOBILE_REQUEST_ACTION });
    const { data: { tempToken }, token } = getState().auth;
    const authService = new AuthApiService();
    return authService.verifyMobile({ ...data, tempToken: tempToken || token })
      .then(async ({ data = {} }) => {
        const storageService = new StorageService();
        const { token, user, role } = data;
        storageService.saveUserToken(token);
        await logInSucceed(dispatch, token, user, role, true);
        dispatch(fetchCurrentUserAction(token, true, false));
        dispatch({ type: VERIFY_MOBILE_SUCCESS_ACTION });
        ToastrService.success(i18n.t('misc.success_phone_verified'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: VERIFY_MOBILE_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function fetchCurrentUserAction(token, hideErrorNotification, dispatchLoginSuccess = true) {
  return dispatch => {
    dispatch({ type: FETCH_CURRENT_USER_REQUEST_ACTION });
    const userService = new UserApiService();
    return userService.getCurrentUser()
      .then(async ({ data = {} }) => {
        const { user, role } = data;
        if (user.language) {
          i18n.changeLanguage(user.language)
        }

        dispatch(getTwilioToken(true, true));
        dispatch(getTwilioVideoToken(true));

        if (user.chatStatus !== userStatuses[0].value) {
          dispatch({ type: CHANGE_CHAT_STATUS_ACTION, payload: true });
        }

        const storageService = new StorageService();
        storageService.saveUserToken(token);
        dispatchLoginSuccess ? await logInSucceed(dispatch, token, user, role) : dispatch({
          type: FETCH_CURRENT_USER_SUCCESS_ACTION,
          payload: { data: user, token, role }
        });
      }).catch((err = {}) => {
        dispatch({ type: FETCH_CURRENT_USER_FAILURE_ACTION });
        const message = err.message || constantMessages.defaultErrorMessage;
        !hideErrorNotification && ToastrService.error(message);
      })

  }
}

export function setUserAvatar(file) {
  return dispatch => {
    dispatch({ type: CHANGE_AVATAR_REQUEST_ACTION });
    const userApiService = new UserApiService();
    return userApiService.changeAvatar(file)
      .then(data => {
        dispatch({ type: CHANGE_AVATAR_SUCCESS_ACTION, payload: data });
        ToastrService.success(i18n.t('misc.success_picture_update'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CHANGE_AVATAR_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}

export function removeUserAvatar() {
  return dispatch => {
    dispatch({ type: REMOVE_AVATAR_REQUEST_ACTION });
    const userApiService = new UserApiService();
    return userApiService.removeAvatar()
      .then(data => {
        dispatch({ type: REMOVE_AVATAR_SUCCESS_ACTION, payload: data });
        ToastrService.success(i18n.t('misc.succes_picture_removed'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: REMOVE_AVATAR_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}

export function sendForgotPasswordEmailAction(data) {
  return dispatch => {
    dispatch({ type: SEND_FORGOT_PASSWORD_REQUEST_ACTION });
    const authApiService = new AuthApiService();
    return authApiService.sendForgotPasswordEmail(data)
      .then(() => {
        dispatch({ type: SEND_FORGOT_PASSWORD_SUCCESS_ACTION });
        ToastrService.success(i18n.t('misc.success_email_reset'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: SEND_FORGOT_PASSWORD_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })

  }
}

export function resetPasswordAction(data) {
  return dispatch => {
    dispatch({ type: RESET_PASSWORD_REQUEST_ACTION });
    const authApiService = new AuthApiService();
    return authApiService.resetPassword(data)
      .then(() => {
        dispatch({ type: RESET_PASSWORD_SUCCESS_ACTION });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: RESET_PASSWORD_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}

export function setPasswordAction(data) {
  return dispatch => {
    dispatch({ type: SET_PASSWORD_REQUEST_ACTION });
    const authApiService = new AuthApiService();
    return authApiService.setPassword(data)
      .then(() => {
        dispatch({ type: SET_PASSWORD_SUCCESS_ACTION });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: SET_PASSWORD_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}

export function changePasswordAction(data) {
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST_ACTION });
    const userApiService = new UserApiService();
    return userApiService.changePassword(data)
      .then(() => {
        dispatch({ type: CHANGE_PASSWORD_SUCCESS_ACTION });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        ToastrService.error(message);
      })

  }
}

export function changePersonalInformationAction(data) {
  return (dispatch, getState) => {
    const user = getState().auth.data;
    const { id } = user;
    dispatch({ type: CHANGE_PERSONAL_INFORMATION_REQUEST_ACTION });
    const userApiService = new UserApiService();
    return userApiService.changePeresonalInformation(data)
      .then(({ data: { updatedUser } }) => {
        if (!updatedUser.id) {
          updatedUser.id = id;
        }

        if (data.language) {
          i18n.changeLanguage(updatedUser.language);
        }

        dispatch({ type: CHANGE_PERSONAL_INFORMATION_SUCCESS_ACTION, payload: { updatedUser } });
        ToastrService.success(i18n.t('misc.success_data_update'))

      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CHANGE_PERSONAL_INFORMATION_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}


export function changePhoneAndEmail(data) {
  return (dispatch, getState) => {
    const user = getState().auth.data;
    const { id } = user;
    dispatch({ type: CHANGE_PERSONAL_INFORMATION_REQUEST_ACTION });
    const userApiService = new UserApiService();
    return userApiService.changePhoneAndEmail(data)
      .then(({ data: { updatedUser, attemptLeft } }) => {
        if (attemptLeft) {
          ToastrService.info(i18n.t('attemptLeft', { attemptLeft }))
        }

        if (!updatedUser.id) {
          updatedUser.id = id;
        }

        dispatch({ type: CHANGE_PERSONAL_INFORMATION_SUCCESS_ACTION, payload: { updatedUser } });
        ToastrService.success(i18n.t('misc.success_phone_email_changing'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CHANGE_PERSONAL_INFORMATION_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}

export function cancelPhoneAndEmailUpdate(data) {
  return (dispatch, getState) => {
    const user = getState().auth.data;
    const { id } = user;
    dispatch({ type: CHANGE_PERSONAL_INFORMATION_REQUEST_ACTION });
    const userApiService = new UserApiService();
    return userApiService.cancelPhoneAndEmailUpdate(data)
      .then(({ data: { updatedUser } }) => {
        if (!updatedUser.id) {
          updatedUser.id = id;
        }

        dispatch({ type: CHANGE_PERSONAL_INFORMATION_SUCCESS_ACTION, payload: { updatedUser } });
        ToastrService.success(i18n.t('misc.success_phone_email_cancelled'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CHANGE_PERSONAL_INFORMATION_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}



export function forcedLogout(message = "", timeout = 10000) {
  return dispatch => {
    ToastrService.error(message);
    const logOutTimeout = setTimeout(() => {
      dispatch(logOut({ logOutTimeout }))
    }, timeout)
  }
}

export function logOut(data) {
  return async (dispatch, getState) => {
    const { logOutTimeout, isManual, isTokenExpired } = data || {};
    dispatch({ type: LOG_OUT_REQUEST_ACTION });
    SoundService.stop(appSounds.CALL_SOUND);
    const { isAppDisabled } = getState().auth;
    if (logOutTimeout) {
      clearTimeout(logOutTimeout)
    }
    const userId = store.getState().auth?.data?.id
    const { calls } = store.getState().calls
    const acceptedCall = getAcceptedCall(calls, userId);
    if (acceptedCall) {
      if (isManual) {
        dispatch(endCall(acceptedCall.twId))
      } else {
        return
      }
    }

    try {
     await CognitoAuth.signOut();
    } catch (error) {
      
    }

    const authApiService = new AuthApiService();
    try {
      const logout = await (isTokenExpired ? true : authApiService.logout())
      if (logout) {
        const { id } = getState().auth.data
        const storageService = new StorageService();
        const userSessions = storageService.checkUserSessions() || {}
        const removeIndex = userSessions[id]?.findIndex(v => v === 'active')
        storageService.updateUserSessions({
          ...userSessions,
          [id]: userSessions[id]?.filter((i, index) => index !== removeIndex)
        })
        storageService.removeUserToken();
        BaseApiService.token = "";
        SocketService.disconnect();
        const twilioClient = getTwilioClient();
        twilioClient && twilioClient.disconnect();
        dispatch({ type: LOG_OUT_SUCCESS_ACTION, payload: isAppDisabled });
        history.push(routes.login.path)
      }
    } catch (err) {
      const message = (err && err.message) || constantMessages.defaultErrorMessage;
      dispatch({ type: LOG_OUT_FAILURE_ACTION, payload: { message } });
      ToastrService.error(message)
    }
  }
}

export function reset() {
  return dispatch => {
    dispatch({ type: RESET })
  }
}

export function getSettings() {
  return dispatch => {
    dispatch({ type: GET_SETTINGS_REQUEST_ACTION })
    const userApiService = new UserApiService();
    return userApiService.getSettings()
      .then((data) => {
        const convertedSettings = {}
        const settings = data.data.settings || []
        settings.forEach(s => {
          convertedSettings[s.key] = s.value
        })
        dispatch({ type: GET_SETTINGS_SUCCESS_ACTION, payload: { settings: convertedSettings } })
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_SETTINGS_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}

export function changeSetting(settingsData) {
  return dispatch => {
    dispatch({ type: SET_SETTINGS_REQUEST_ACTION });
    const userApiService = new UserApiService();
    return userApiService.changeSetting(settingsData)
      .then(({ data }) => {
        const setting = data.setting
        dispatch({ type: SET_SETTINGS_SUCCESS_ACTION, payload: { setting } });
        ToastrService.success(i18n.t('misc.success_settings_changed'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: SET_SETTINGS_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}

export function updateSetting(settingsData) {
  return dispatch => {
    dispatch({ type: UPDATE_SETTINGS_REQUEST_ACTION });
    const userApiService = new UserApiService();
    return userApiService.updateSetting(settingsData)
      .then(({ data }) => {
        const { user } = data;
        dispatch({ type: UPDATE_SETTINGS_SUCCESS_ACTION, payload: { user } });
        ToastrService.success(i18n.t('misc.success_settings_changed'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UPDATE_SETTINGS_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message);
      })
  }
}

export function changePhoneNumber(data) {
  return (dispatch, getState) => {
    const { data: { tempToken } } = getState().auth;
    dispatch({ type: CHANGE_PHONE_NUMBER_REQUEST_ACTION });
    const authApiService = new AuthApiService();
    return authApiService.changePhone(data, tempToken)
      .then((response) => {
        if (response?.data.attemptLeft) {
          ToastrService.info(i18n.t('attemptLeft', { attemptLeft: response.data.attemptLeft }))
        }

        if (response?.token || response?.data?.message) {
          dispatch({ type: CHANGE_PHONE_NUMBER_SUCCESS_ACTION, payload: data });
          ToastrService.success(data.message || i18n.t('misc.success_phone_changed'))
        } else {
          dispatch({ type: CHANGE_PHONE_NUMBER_FAILURE_ACTION, payload: {} });
        }
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CHANGE_PHONE_NUMBER_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function changeEmail(data) {
  return (dispatch, getState) => {
    const { data: { tempToken } } = getState().auth;
    dispatch({ type: CHANGE_EMAIL_REQUEST_ACTION });
    const authApiService = new AuthApiService();
    return authApiService.changeEmail(data, tempToken)
      .then((response) => {
        if (response?.token || response?.data?.message) {
          dispatch({ type: CHANGE_EMAIL_SUCCESS_ACTION, payload: data });
          ToastrService.success(data.message || i18n.t('misc.success_email_changed'))
        } else {
          dispatch({ type: CHANGE_EMAIL_FAILURE_ACTION, payload: {} });
        }
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CHANGE_EMAIL_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function updateUserToken() {
  return (dispatch, getState) => {
    dispatch({ type: REFRESH_TOKEN_REQUEST_ACTION });
    const authApiService = new AuthApiService();
    const { token: currentToken, data: { id } } = getState().auth;
    if (currentToken) {
      const { calls } = getState().calls;
      const socketId = SocketService.connection?.io?.opts?.query?.uuid;

      return authApiService.refreshToken({ currentToken })
        .then(async (response) => {
          const { token: localToken } = getState().auth;
          if (localToken) {
            const token = response?.data?.token || "";
            BaseApiService.token = token;
            const storageService = new StorageService();
            storageService.saveUserToken(token);
            const hasCalls = calls.some(call => {
              return !!call.participants?.find(participant =>
                participant.id === id &&
                (
                  participant.status === callStatusesForParticipant.RINGING ||
                  (participant.status === callStatusesForParticipant.ACCEPTED && participant.socketId === socketId)
                )
              )
            });

            if (!hasCalls) {
              await SocketService.connect(token);
              addSocketEvents();
              dispatch(getTwilioToken(true, false));
              dispatch(getTwilioVideoToken(true));
            }

            dispatch({ type: REFRESH_TOKEN_SUCCESS_ACTION, payload: token })
          }
        }).catch((err = {}) => {
          const message = err.message || constantMessages.defaultErrorMessage;
          dispatch({ type: REFRESH_TOKEN_FAILURE_ACTION, payload: { message } });
          ToastrService.error(message);
        })
    }
  }
}

export function setIdleTimer(timerAPI) {
  return dispatch => {
    dispatch({ type: SET_IDLE_TIMER, payload: timerAPI });
  }
}
