import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import UnverifiedUsersComponent from '../../../components/Admin/Components/User/UsersComponent/UnverifiedUsersComponent';
import {
  FETCH_USERS_LIST_PREFIX,
  getUsersList,
  deleteUser,
  DELETE_USER_PREFIX,
} from "../../../reducers/user";
import {createLoadingSelector} from "../../../selectors/loading";
import {createErrorMessageSelector} from "../../../selectors/error";

const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.users.total || 0) / (state.users.pageSize || 1));
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    pageCount,
    users: state.users.users,
    authUser: state.auth.data,
    sessionTimeout: state.auth.settings?.sessionTimeout,
    loadingUsersList: createLoadingSelector([FETCH_USERS_LIST_PREFIX])(state),
    deletingUser: createLoadingSelector([DELETE_USER_PREFIX])(state),
    errorOnDeletingUser: createErrorMessageSelector([DELETE_USER_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getUsersList,
  deleteUser,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnverifiedUsersComponent);
