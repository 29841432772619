import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getUserAvatarLetters } from "../../../../utils/function-utils";
import { useTranslation } from 'react-i18next';

const ResetOrDeleteUserModal = ({ deleteUser, user = {}, toggle, submit, isOpen }) => {
 const { t } = useTranslation('common')
  const phone = `${user.phoneCountryCode || ""}${user.phone}`;

  return (

    <Modal isOpen={isOpen} toggle={toggle} className={"resetUserPasswordModal"} size="sm">
      <ModalHeader toggle={toggle}>{deleteUser ? t('admin.DEL_USER') : t('admin.RESET_PASS')}</ModalHeader>
      <ModalBody className="d-flex flex-column flex-wrap px-4 pb-0">
        <div className="avatarContainer mb-4">
          {getUserAvatarLetters(user)}
        </div>
        <div className="d-flex">
          <p className="dataLabel">{t('Name')}</p>
          <p className="dataValue">{user.firstName}</p>
        </div>
        <div className="d-flex">
          <p className="dataLabel">{t('Surname')}</p>
          <p className="dataValue">{user.lastName}</p>
        </div>
        <div className="d-flex">
          <p className="dataLabel">{t('Email')}</p>
          <p className="dataValue">{user.email}</p>
        </div>
        <div className="d-flex">
          <p className="dataLabel">{t('Phone')}</p>
          <p className="dataValue">{phone}</p>
        </div>
        <div className="d-flex">
          <p className="dataLabel">{t('Role')}</p>
          <p className="dataValue d-flex align-items-center"><div className={`circle mr-1 ${user.role.name === 'Admin' ? 'red' : 'blue'}`} />{user.role && user.role.name}</p>
        </div>
        <div className="d-flex">
          <p className="dataLabel">{t('Status')}</p>
          <p className={`dataValue status ${user.active ? 'active' : 'inactive'}`}>{user.active ? t('Active') : t('Inactive')}</p>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button color="secondary" onClick={toggle}>{t('CANCEL')}</Button>
        <Button color="primary" onClick={submit}>{deleteUser ? t('DELETE') : t('RESET')}</Button>
      </ModalFooter>
    </Modal>
  )
};

export default ResetOrDeleteUserModal;
