import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Col, Container, Button, Label } from "reactstrap";
import Checkbox from 'rc-checkbox'
import { routes } from '../../config';
import "./index.scss";
import 'rc-checkbox/assets/index.css';

import { InputField, Loader, SelectField } from '../UIComponents';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { LanguageSelector } from '../UIComponents/LanguageSelector';

class LoginComponent extends Component {
  state = {
    email: process.env.REACT_APP_DEV_EMAIL ?? '',
    password: process.env.REACT_APP_DEV_PASSWORD ?? '',
    remember: false,
    showPassword: false,
    errors: {},
  };

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  componentDidMount() {
    const { token, isSelfRegistrationEnabled } = this.props;
    if (isSelfRegistrationEnabled === null) {
      this.props.getSelfRegistrationStatus();
    }
    !!token && this.props.history.push(routes.recent.path + window.location.search)
  }

  componentDidUpdate(prevProps) {
    const { token, history } = this.props;
    if (!!token) {
      history.push(routes.recent.path + window.location.search);
    }
  }

  handleLoginClick = () => {
    const { email, password, remember } = this.state, errors = {};
    let isValid = true;
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!email) {
      errors.email = this.props.t('Required');
      isValid = false;
    } else if (!re.test(email)) {
      errors.email = this.props.t('invalid_email');
      isValid = false;
    }
    if (!password) {
      errors.password = this.props.t('Required');
      isValid = false;
    }

    this.setState({ errors });
    isValid && this.props.logInAction({ username: email, password, remember });
  };

  render() {
    const { errors, email, password, remember, showPassword } = this.state;
    const { loading, history, isSelfRegistrationEnabled, t } = this.props;

    if (loading) {
      return (
        <Container fluid className="pageContainer loginLoading bg-light">
          <Loader />
        </Container>
      )
    }

    return (
      <Container fluid className="authPageContainer pageContainer align-items-center pt-64 bg-light">
        <div className="contentContainer py-20 w-100 py-5 justify-content-center d-flex flex-column align-items-center">
          <h1 className="authPageTitle">{t('auth.Welcome')}</h1>
          <h1 className="authPageSubTitle">{t('auth.login_to_ecs')}</h1>
          <Col lg={10} xs={12} className="d-flex flex-column align-items-center ieCol">
            <Col md={6} xs={12} className="px-0 ieCol mb-2">
              <InputField
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                error={errors.email}
                onChange={(event) => this.onFieldValueChange('email', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleLoginClick()}
              />
            </Col>
            <Col md={6} xs={12} className="px-0 ieCol">
              <InputField
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder={t("Password")}
                value={password}
                error={errors.password}
                required={t('Required')}
                onChange={(event) => this.onFieldValueChange('password', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleLoginClick()}
              />
              <img onClick={() => this.onFieldValueChange('showPassword', !showPassword)}
                src="/assets/images/show-password.svg"
                width="25"
                alt={t("show_pass")}
                className={`${showPassword ? '' : 'disabled '} showPasswordIcon clickable`}
              />
            </Col>

            <Col md={6} xs={12} className="d-flex justify-content-between px-0 w-100 mt-2 mb-4 ieCol">
              <Label className="rememberMeCheckBox">
                <Checkbox
                  className="escCheckbox"
                  defaultChecked={remember}
                  onChange={() => this.onFieldValueChange('remember', !remember)}
                />
                &nbsp; {t('auth.Remember')}
              </Label>
              <NavLink to={routes.forgotPassword.path} className="linkButton forgotPasswordButton">{t('auth.Forgot')}</NavLink>
            </Col>

            <Col md={6} xs={12} className="d-flex px-0 w-100 mb-5 submitButtonContainer ieCol">
              <Button className="submitButton w-100" onClick={this.handleLoginClick}>{t('auth.LOGIN')}</Button>
            </Col>

            {isSelfRegistrationEnabled === true &&
              <>
                <h1 className="authPageTitle question mt-5">{t('auth.dont_have_account')}</h1>

                <Col md={6} xs={12} className="d-flex px-0 w-100 ieCol">
                  <Button className="cancelButton w-100"
                    onClick={() => history.push(routes.register.path)}>{t('auth.REGISTER')}</Button>
                </Col>
              </>
            }

            <LanguageSelector onChange={i18n.changeLanguage} value={i18n.language} />

          </Col>
        </div>
      </Container>
    );
  }
}

export default withTranslation('common')(withRouter(LoginComponent))
