import BaseApiService from './base';
import SocketService from '../socket';
import {SocketMessageType} from "../../config";

/**
 * Users Api Service - for making the users requests and passing results to the action that made the request
 */
export default class UserApiService extends BaseApiService {
  getUsersList(params) {
    return this.request({
      method: 'get',
      url: '/user',
      params,
      withCredentials: true
    });
  }

  activateUser(userId) {
    return this.request({
      method: 'post',
      url: `/user/activate`,
      data: {userId},
      withCredentials: true
    });
  }

  deactivateUser(userId) {
    return this.request({
      method: 'post',
      url: `/user/deactivate`,
      data: {userId},
      withCredentials: true
    });
  }

  createUser(data) {
    return this.request({
      method: 'post',
      url: `/user`,
      data,
      withCredentials: true
    });
  }

  bulkUsersCreate(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.request({
      method: 'post',
      url: `/user/import-from-csv`,
      data: formData,
      withCredentials: true
    });
  }

  updateUser(data) {
    return this.request({
      method: 'post',
      url: `/user/update-user`,
      data,
      withCredentials: true
    });
  }

  getUserById(id) {
    return this.request({
      method: 'get',
      url: '/user/get-user',
      params: {id},
      withCredentials: true
    });
  }

  deleteUser(id) {
    return this.request({
      method: 'delete',
      url: '/user/delete-user',
      params: {id},
      withCredentials: true
    });
  }

  getCurrentUser() {
    return this.request({
      method: 'get',
      url: '/setting/me',
      withCredentials: true
    });
  }

  changePassword(data) {
    return this.request({
      method: 'post',
      url: '/setting/password',
      data,
      withCredentials: true
    });
  }

  changePeresonalInformation(data) {
    return this.request({
      method: 'post',
      url: '/setting/account',
      data,
      withCredentials: true
    });
  }

  changePhoneAndEmail(data) {
    return this.request({
      method: 'post',
      url: '/setting/phone-email-update',
      data,
      withCredentials: true
    });
  }

  cancelPhoneAndEmailUpdate(data) {
    return this.request({
      method: 'post',
      url: '/setting/cancel-phone-email-update',
      data,
      withCredentials: true
    });
  }

  changeAvatar(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.request({
      method: 'post',
      url: '/setting/upload-avatar',
      data: formData,
      withCredentials: true
    });
  }

  removeAvatar() {
    return this.request({
      method: 'delete',
      url: '/setting/delete-avatar',
      withCredentials: true
    });
  }

  changeSetting(data) {
    return this.request({
      method: 'post',
      url: '/setting',
      data,
      withCredentials: true
    })
  }

  resetUserMFA(data) {
    return this.request({
      method: 'post',
      url: '/user/reset-mfa',
      data,
      withCredentials: true
    })
  }

  resetMfaData(data) {
    return this.request({
      method: 'post',
      url: '/user/set-mfa-data',
      data,
      withCredentials: false
    })
  }

  updateSetting(data) {
    return this.request({
      method: 'post',
      url: '/user/update-settings',
      data,
      withCredentials: true
    })
  }

  verifyUpdatedMobile(data) {
    return this.request({
      method: 'post',
      url: '/setting/verify-updated-phone',
      withCredentials: true,
      data
    })
  }

  getSettings() {
    return this.request({
      method: 'get',
      url: '/setting',
      withCredentials: true
    });
  }

  changeUserAvatar(file, id) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    return this.request({
      method: 'post',
      url: '/user/upload-avatar',
      data: formData,
      withCredentials: true
    });
  }

  removeUserAvatar(id) {
    return this.request({
      method: 'delete',
      url: '/user/delete-avatar',
      data: {id},
      withCredentials: true
    });
  }

  updateUserPermissions(data) {
    return this.request({
      method: 'post',
      url: '/user/permission',
      data,
      withCredentials: true
    });
  }

  updateAllUsersPermissions(data) {
    return this.request({
      method: 'post',
      url: '/user/permission/all',
      data,
      withCredentials: true
    });
  }

  changeUsersRole(data) {
    return this.request({
      method: 'post',
      url: '/user/update-role',
      data,
      withCredentials: true
    })
  }

  getUserLogs(params) {
    return this.request({
      method: 'get',
      url: '/user/logs',
      params,
      withCredentials: true
    })
  }

  exportUserLogs(params) {
    return this.request({
      method: 'get',
      url: '/user/export-logs',
      params,
      withCredentials: true,
      responseType: 'blob'
    })
  }

  changeChatStatus(userId, newStatus) {
    return SocketService.send(SocketMessageType.CHANGE_CHAT_STATUS, {userId, newStatus});
  }

}
