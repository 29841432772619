import BaseApiService from './services/api/base';
import { trialLogInAction } from "./reducers/auth";
import store from './store';
import { Amplify } from 'aws-amplify';
require('dotenv').config();

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_CLIENT_ID,
    region: process.env.REACT_APP_COGNITO_POOL_REGION,
  }
})

const API_URL = process.env.REACT_APP_API_URL;
// Set base api url based on REACT_APP_API_URL variable value from .env file
const setServiceURL = () => {
  BaseApiService.baseApiUrl = API_URL;
};

// Make a trial log in to find out is there already logged in user or not
const makeTrialLogin = () => {
  store.dispatch(trialLogInAction())
};

const setupProject = () => {
  setServiceURL();
  makeTrialLogin();
};

setupProject();
